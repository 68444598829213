nav {
    position: fixed;
    z-index: 100;
    width: 100%;
    padding: 0 15px;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

nav:has(ul.active) {
    background-color: rgba(255, 255, 255, 0.15);
    background-color: #1e1e1e;
    backdrop-filter: blur(6px);
}

a {
    color: white;
    text-decoration: none;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), 
    linear-gradient(#ffffff, #ffffff);
    background-size: 100% 1.5px, 0 1.5px;
    background-position: 100% 100%, 100% 75%;
    background-repeat: no-repeat;
    transition: background-size .6s cubic-bezier(.19,1,.22,1);
}

li:not(.toggle) > a:hover {
    background-size: 0 1.5px, 100% 1.5px;
    background-position: 100% 100%, 0 75%;
}

.menu, .submenu {
    list-style-type: none;
}

.logo {
    font-family: 'Raleway', sans-serif;
    font-size: 22px;
    padding: 7.5px 10px 7.5px 0;
    font-weight: 300;
}

.item {
    padding: 10px;
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    text-transform: capitalize;
}

.item.button {
    padding: 9px 5px;
}

.item:not(.button) a:hover, .item a:hover::after {
    cursor: pointer;
    color: #cccccc;
}

/* Mobile menu */
.menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.menu li a {
    display: inline-block;
    padding: 15px 5px;
}

.menu li.subitem a {
    margin: 0 10px;
}

.toggle {
    order: 1;
    font-size: 20px;
}

.item.button {
    order: 2;
}

.item {
    order: 3;
    width: 100%;
    text-align: center;
    display: none;
}

.active .item {
    display: block;
}

.button.secondary {
    border-bottom: 1px #444 solid;
}

/* Submenus */
.submenu {
    display: none;
}

.submenu-active .submenu {
    display: block;
}

.has-submenu > a::after {
    font-family: "Font Awesome 5 free";
    font-size: 12px;
    line-height: 16px;
    font-weight: 900;
    content: "\f078";
    color: white;
    padding-left: 5px;
}

.subitem a {
    padding: 10px 15px;
}

.submenu-active {
    background-color: #111;
    border-radius: 3px;
}

/* Tablet menu */

@media all and (min-width: 700px) {
    .menu {
        justify-content: center;
    }

    .logo {
        flex: 1;
    }

    .item.button {
        width: auto;
        order: 1;
        display: block;
    }

    .toggle {
        padding-left: 10px;
        flex: 0;
        text-align: right;
        order: 2;
    }

    .button a {
        background: #0080ff;
        border: 1px royalblue solid;
    }

    .button.secondary {
        border: 0;
    }

    .button.secondary a {
        background: transparent;
        border: 1px #0080ff solid;
    }

    .button a:hover {
        text-decoration: none;
    }

    .button:not(.secondary) a:hover {
        background: royalblue;
        border-color: darkblue;
    }
}

/* Desktop menu */
@media all and (min-width: 960px) {
    .item {
        order: 1;
        position: relative;
        display: block;
        width: auto;
    }

    .button {
        order: 2;
    }

    .submenu-active .submenu {
        display: block;
        position: absolute;
        left: 0;
        top: 68px;
        min-width: 100%;
        background: #111111;
    }

    .toggle {
        display: none;
    }

    .submenu-active {
        border-radius: 0;
    }
}

/* Make log in and sign up buttons invisible */
.item.button, .item.button.secondary {
    display: none;
}