/* Raleway */
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Source Code Pro */
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Lato */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

/* Roboto */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    background-color: #1e1e1e;
}

section:not(#header):not(#footer) {
    background-color: #1e1e1e;
    width: 100vw;
}

html {
    overflow-x: hidden;
}

body {
    font-family: sans-serif;
    font-size: 16px;
    overflow-x: hidden;
}

.invisible {
    display: none;
}

.transparent {
    color: transparent;
    background-color: transparent;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Standard styling */
section.standard {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: #1e1e1e;
}

h1.standard {
    position: absolute;
    padding-left: 100px;
    top: 25vh;
    transform: translateY(-50%);
    color: #ffffff;
    font-family: 'Source Code Pro', monospace;
    font-size: 80px;
}

@media only screen and (max-width: 700px) {
    h1.standard {
        position: absolute;
        padding: 0 25px;
        text-align: center;
        top: 25vh;
        transform: translateY(-50%);
        font-size: 40px;
    }
}

@media only screen and (min-width: 700px) and (max-width: 960px) {
    h1.standard {
        position: absolute;
        padding: 0 25px;
        text-align: center;
        top: 25vh;
        transform: translateY(-50%);
    }
}