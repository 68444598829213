.matrix-fade-in {
    opacity: 0;
}

#directory {
    height: 55vh;
}

@keyframes rainbow-hue {
    0% {
        filter: hue-rotate(240);
    }

    100% {
        filter: hue-rotate(600deg);
    }
}

#directory #matrix-canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition-delay: 0.5s;
    transition-duration: 1s;
    filter: hue-rotate(240deg);
    animation: rainbow-hue 10s linear infinite;
}