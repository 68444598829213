.fade-in-section, .fade-in-div {
  opacity: 0;
  transform: translate(0, 10%);
  visibility: hidden;
  transition: opacity 300ms ease-out, transform 300ms ease-out;
  will-change: opacity, visibility;
}

.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
  display: flex; 
}