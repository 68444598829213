#home {
    max-height: calc(100vh);
    overflow: hidden;
}

.image-fade-in {
    opacity: 0;
}

#home-image {
    position: relative;
    left: 0%;
    top: 0%;
    filter: brightness(85%);
    width: 120%;
    object-fit: cover;
    object-position: 5% 95%;
}

#home-image-gradient {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 120%;
    height: 120%;
    background-image: radial-gradient(circle, rgba(30, 30, 30, 0), rgb(30, 30, 30));
}

#home-image, #home-image-gradient {
    transition-delay: 0.5s;
    transition-duration: 1s;
}

#home-about {
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-height: calc(100vh);
    overflow: hidden;
    color: #ffffff;
}

#home-about-profile-image-container {
    position: relative;
    aspect-ratio: 2316/3088;
    height: 80%;
}

#home-about-profile-image {
    aspect-ratio: 2316/3088;
    height: 100%;
    border-radius: 32px;
}

#home-about-profile-blurb {
    flex: 0.75;
}

#home-about-profile-title {
    font-size: calc(3.35vw + 1em);
}

.home-about-profile-paragraph {
    text-indent: 1em;
    padding-top: 2vh;
    font-size: calc(0.65vw + 1em);
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

#home-my-expertise {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    max-height: calc(65vh);
    overflow: hidden;
    color: #ffffff;
}

#home-expertise-title-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0.25;
}

#home-expertise-title {
    font-size: calc(3.35vw + 1em);
}

#home-expertise-grid-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0.475;
    width: 55%;
}

#home-expertise-grid {
    display: flex;
    position: relative;
    height: 100%;
    aspect-ratio: 3.3;
}

.home-expertise-grid-item {
    flex: 1;
    border: 1px solid #ffffff;
    border-top: 3px solid #ffffff;
    border-bottom: 3px solid #ffffff;
}

.home-expertise-outer-r-grid-item {
    border-right: 3px solid #ffffff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.home-expertise-outer-l-grid-item {
    border-left: 3px solid #ffffff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

@media screen and (orientation:portrait) {
    #home-about {
        display: inline;
    }

    #home-about-profile-image-container {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 2.35vh;
        margin-bottom: 2.35vh;
        width: 80vw;
        height: 40vh;
    }

    #home-about-profile-image {
        position: absolute;
        object-position: 0% 75%;
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: 32px;
    }

    #home-about-profile-title {
        width: 100%;
        text-align: center;
    }
    
    .home-about-profile-paragraph {
        position: relative;
        left: 10%;
        width: 80%;
    }

    #home-my-expertise {
        max-height: none;
        margin-bottom: 30px;
    }

    #home-expertise-grid-section {
        flex: 1;
    }

    #home-expertise-grid {
        flex-direction: column;
        height: 100%;
        aspect-ratio: 1/2.4;
    }

    .home-expertise-grid-item {
        flex: 1;
        border: 1px solid #ffffff;
        border-top: 3px solid #ffffff;
        border-bottom: 3px solid #ffffff;
    }

    .home-expertise-grid-item {
        border: 1px solid #ffffff;
        border-left: 3px solid #ffffff;
        border-right: 3px solid #ffffff;
    }
    
    .home-expertise-outer-r-grid-item {
        border-bottom: 3px solid #ffffff;
        border-radius: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    
    .home-expertise-outer-l-grid-item {
        border-top: 3px solid #ffffff;
        border-radius: 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
}