.matrix-fade-in {
    opacity: 0;
}

#portfolio {
    height: 55vh;
}

#portfolio #matrix-canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition-delay: 0.5s;
    transition-duration: 1s;
}