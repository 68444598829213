#directory-menu {
    min-height: 90vh;
    margin-bottom: 10vh;
}

.list-projects {
    position: relative;
    display: flex;
    flex-flow: column;
    left: 50%;
    transform: translateX(-50%);
    margin: 80px 0;
    width: 90vw;
    height: 90vh;
    background-color: #2d2d2d;
    box-shadow: 5px 5px #373737;
    border-radius: 6px;
}

.list-header {
    position: relative;
    width: 100%;
    flex: 0 1 auto;
}

.list-search-bar-container {
    position: relative;
    display: inline-block;
    width: calc(100% - 40px);
    margin: 20px 25px;
    min-height: 50px;
}

.list-search-bar {
    position: relative;
    width: 100%;
    height: 50px;
    border: 0;
    border-radius: 10px;
    font-size: 30px;
    padding-left: 10px;
}

.list-search-bar-icon {
    position: absolute;
    top: -1px;
    right: -1px;
    aspect-ratio: 1;
    height: calc(100% + 2px);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #373737;
}

.list-search-bar-icon > img, .list-search-bar-icon > svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 75%;
    width: 75%;
    filter: brightness(0) invert(1);
}

.list-entries {
    flex: 1 1 auto;
    position: relative;
    display: block;
    height: 100%;
    overflow-y: scroll;
}

.list-entries > table {
    border-collapse: collapse;
    width: 100%;
}

.list-entries::-webkit-scrollbar {
    width: 7px;
}
  
.list-entries::-webkit-scrollbar-track {
    display: none;
}
   
.list-entries::-webkit-scrollbar-thumb {
    background: #ffffff; 
    border-radius: 10px;
}

.list-entry {
    position: relative;
    display: inline-block;
    width: 98%;
    left: 50%;
    transform: translateX(-50%);
    min-height: 45px;
    border-bottom: 2px solid #ffffff;
}

.list-entry:last-child {
    border: 0;
}

.list-entry > a {
    width: 100%;
    height: 100%;
}

.list-entry td {
    margin: 10px;
    font-size: 20px;
}

.list-entry .list-entry-name {
    float: left;
}

.list-entry .list-entry-date {
    float: right;
}

.list-footer {
    flex: 0 1 65px;
}

/* Entry Modal */

html:has(.entry-modal-active) {
    overflow-y: hidden;
}

.entry-modal-outer {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    transition: opacity 0.2s 0s ease-in-out;
}

.entry-modal-outer.entry-modal-active {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1);
}

.entry-modal {
    position: relative;
    top: 5px;
    padding: 30px;
    max-width: 1200px;
    width: calc(100% - 40px);
    background-color: #ffffff;
    border-radius: 12px;
    overflow: hidden;
    transform: scale(0.8);
    transition: transform 0.2s 0s ease-in-out;
}

.entry-modal-outer.entry-modal-active > .entry-modal {
    transform: scale(1);
}

.entry-modal-inner {
    position: relative;
    width: 100%;
    margin-top: 20px;
    max-height: calc(85vh - 60px);
    overflow-y: scroll;
    overflow-x: hidden;
}

.entry-modal-inner::-webkit-scrollbar {
    width: 7px;
}
  
.entry-modal-inner::-webkit-scrollbar-track {
    display: none;
}
   
.entry-modal-inner::-webkit-scrollbar-thumb {
    background: #ababab; 
    border-radius: 10px;
}

.entry-modal-title {
    text-align: center;
    font-size: 32px;
}

.slider-wrapper {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 95%;
    
}

.slider-wrapper:not(:has(img)) {
    display: none;
}

.slider {
    display: flex;
    aspect-ratio: 16 / 9;
    overflow-x: auto;
    box-shadow: 0 1.5rem 3rem -0.75rem hsla(0, 0%, 0%, 0.25);
    border-radius: 12px;
}

.entry-modal-active .slider {
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.slider::-webkit-scrollbar {
    display: none;
}

.slider img {
    flex: 1 0 100%;
    scroll-snap-align: start;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slider-nav {
    position: absolute;
    display: flex;
    background-color: #1e1e1e;
    padding: 10px;
    border-radius: 1000px;
    opacity: 0.8;
    column-gap: 1rem;
    bottom: 1.25rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

.slider-nav a {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: #ffffff;
    opacity: 0.75;
    transform: opacity ease 250ms;
}

.slider-nav a:hover {
    opacity: 1;
}

.entry-modal-description {
    padding: 15px;
    font-size: 18px;
}

.entry-modal .close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 20px;
    color: #b4b4b4;
    transition: all 0.2s ease;
}

.entry-modal .close:hover {
    cursor: pointer;
    color: #333333;
}

.button {
    display: flex;
    justify-content: flex-end;
    width: calc(100% - 15px);
    margin-top: 15px;
    margin-left: 8px;
}

.button button {
    outline: none;
    border: none;
    padding: 6px 12px;
    border-radius: 6px;
    background-color: #6f93f6;
    margin-left: 8px;
    color: #ffffff;
    font-size: 22px;
    transition: all 0.3s ease;
}

.button button:hover {
    background-color: #275df1;
    cursor: pointer;
}

.button button.cancel {
    background-color: #f082ac;
}

.button button.cancel:hover {
    background-color: #ec5f95;
}